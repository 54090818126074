import $ from 'jquery';
import Venobox from 'venobox/venobox/venobox';

// if using youtube's nocookie approach see bugfix
// https://github.com/nicolafranchini/VenoBox/issues/161
// has to be manually applied in node_module ~venobox/venobox/venobox.js :(

const selectorVenobox = '.venobox';

// @link http://veno.es/venobox/
function initVenobox() {
  // console.log('venobox init');
  $(selectorVenobox).venobox({
    overlayColor: 'rgba(0, 132, 61, .95)',
    titleattr: 'data-title',
    titlePosition: 'bottom',
  });
}

function init() {
  if (document.querySelector(selectorVenobox) !== null) {
    initVenobox();
  }
}

export default {
  init,
};
