// https://github.com/verlok/lazyload
import LazyLoad from 'vanilla-lazyload';

const loadLazy = () => {
  const lazyLoadOptions = {
    elements_selector: '.lazy-vanilla',
  };
  // eslint-disable-next-line no-unused-vars
  const pageLazyLoad = new LazyLoad(lazyLoadOptions);
};

export function initLazy() {
  loadLazy();
}
