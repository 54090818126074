import $ from 'jquery';
import { scrollToElement } from '../../utils/helper';

const selectorScrollDownParent = '.js-scroll-down';
const selectorBurgerButton = '.js-hamburger-button';
const selectorMenuMobile = '.js-container-menu-mobile';
const burgerButton = document.querySelector(selectorBurgerButton);
const menuMobile = document.querySelector(selectorMenuMobile);

const initEventlistener = () => {
  burgerButton.addEventListener('click', () => {
    console.log('burger button clicked');
    burgerButton.classList.toggle('is-active');
    menuMobile.classList.toggle('is-active');
  });
};

const closeMobileMenu = () => {
  burgerButton.classList.remove('is-active');
  menuMobile.classList.remove('is-active');
};

const initScrollDown = () => {
  // Select all links with hashes
  $(`${selectorScrollDownParent} a`).click(function (event) {
    closeMobileMenu();
    // On-page links
    if (
      window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') &&
      window.location.hostname === this.hostname
    ) {
      // Figure out element to scroll to
      let target = $(this.hash);
      target = target.length ? target : $(`[name=${this.hash.slice(1)}]`);
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        console.log('scrolling?', target.selector);
        scrollToElement(`${target.selector}`, 1000, -140);
        // const topDist = target.offset().top - 800;
        // $('html, body').animate(
        //   {
        //     scrollTop: topDist,
        //   },
        //   1000,
        //   function () {
        //     // Callback after animation
        //     // Must change focus!
        //     const $target = $(target);
        //     $target.focus();
        //     if ($target.is(':focus')) {
        //       // Checking if the target was focused
        //       return false;
        //     }
        //     $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
        //     $target.focus(); // Set focus again
        //   },
        // );
      }
    }
  });
};

function init() {
  if (document.querySelector(selectorBurgerButton) !== null) {
    initEventlistener();
    initScrollDown();
  }
}

export default {
  init,
};
