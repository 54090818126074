import $ from 'jquery';
/*
import $ from 'jquery'
import 'url-search-params-polyfill'
import axios from 'axios'

export function scrollToElement(selector, t, verticalOff) {
  const time = typeof (t) !== 'undefined' ? t : 1000
  const verticalOffset = typeof (verticalOff) !== 'undefined' ? verticalOff : 0
  const element = $(selector)
  const offset = element.offset()
  const offsetTop = offset.top + verticalOffset
  $('html, body').animate({
    scrollTop: offsetTop
  }, time)
}

export function fetchData(ajaxUrl, postParams) {
  const params = new URLSearchParams(postParams)

  return axios.post(ajaxUrl, params)
  .then(response => response.data)
  .catch(error => error)
} */

/**
 * This function checks if a given element is in the browser view.
 *
 * Use parameters to define if element has to be fully or partially in view
 * > default is fully in view.
 *
 * @access     private
 *
 * @param {object}  element         HTML element to check viewport against
 * @param {string}  mode            [ default: 'fully' ] | 'partially' | 'sufficiently'
 * @param {number}  delta           [ default: 400 ] - pixels in view in mode 'sufficiently'
 *
 * @returns {Boolean} true or false
 */
export function isInView(element, mode = 'fully', delta = 400) {
  const position = element.getBoundingClientRect();
  const visibleTop = window.innerHeight - delta;

  switch (mode) {
    case 'fully':
      // checking whether fully visible
      if (position.top >= 0 && position.bottom <= window.innerHeight) {
        return true;
      }
      return false;

    case 'partially':
      // checking for partial visibility
      if (position.top < window.innerHeight && position.bottom >= 0) {
        return true;
      }
      return false;

    case 'sufficiently':
      // checking for 'sufficiently' (delta px in view):
      if (position.top < visibleTop && position.bottom >= 0) {
        return true;
      }
      return false;

    default:
      return false;
  }
}

/**
 * This function toggles a css classname on a passed element
 *
 *
 * @access     private
 *
 * @param {object}  element         HTML element the classname toggle should be fired on
 * @param {string}  classname       The classname to be toggled
 */
export function toggleClassname(el, classname) {
  el.classList.toggle(classname);
}

export function scrollToElement(selector, t, verticalOff) {
  const time = typeof t !== 'undefined' ? t : 1000;
  const verticalOffset = typeof verticalOff !== 'undefined' ? verticalOff : 0;
  const element = $(selector);
  const offset = element.offset();
  const offsetTop = offset.top + verticalOffset;
  $('html, body').animate(
    {
      scrollTop: offsetTop,
    },
    time,
  );
}
