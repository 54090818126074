import domready from 'domready';
// import swiper from './components/swiper/index';
import { initLazy } from './components/lazy-load/index';
import burgerButton from './components/menu/index';
import navbar from './components/navbar/index';
import foundation from './components/foundation/index';
import venobox from './components/venobox/index';

function startApp() {
  // console.log('js app started');
  initLazy();
  burgerButton.init();
  navbar.init();
  foundation.init();
  venobox.init();
  // swiper.init();
}

domready(() => {
  startApp();
});
